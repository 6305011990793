import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import AuthUserContext from '../../providers/AuthProvider'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getSuggestedHerbsToReview } from '../../services/herb-review.services';
import SuggestedHerbsToReview from '../SuggestedHerbsToReview';
import withLocation from '../common/withLocation';
import { ManageFavoritesStyled } from '.';
const Container = styled.div`
  margin-top: 50px;
`

const ThankYouBox = styled.div`
  height: 50px;
  border: 1px solid var(--Color1);
  border-radius: 4px;
  font-family: var(--Font1NotCondensed);
  color: var(--Color1);
  background: rgba(5, 170, 151, 0.05);

  .inner-box{
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 10px;
    height: 100%;
    border: 2px solid rgba(5, 170, 151, 0.5);

    .inner-text{
      padding-left: 10px;
    }
  }
`

function ExistingReview(props) {
  let { user } = useContext(AuthUserContext);




  return (
    <ManageFavoritesStyled>
      <br />
      <ThankYouBox>
        <div className="inner-box">
          <CheckCircleIcon />
          <span className="inner-text">
            Thank you for your review {user.first_name}
          </span>

        </div>
      </ThankYouBox>
      <SuggestedHerbsToReview />

    </ManageFavoritesStyled>
  )
}


export default withLocation(ExistingReview)