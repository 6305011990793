import React from "react"
import Layout from "../../components/Layout"
import ProtectedPage from '../../components/common/ProtectedPage'
import ExistingReview from "../../components/CreateHerbReview/ExistingReview"

export default () => (
  <Layout seoProps={{title: "Thank You!!"}}>
    <ProtectedPage>
      <ExistingReview />
    </ProtectedPage>
  </Layout>

)

