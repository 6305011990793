import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import RatingInput from '../common/RatingInput'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Link } from 'gatsby'
import { CREATE_HERB_REVIEW } from '../Layout/routes';
import { navigate } from "@reach/router"
import { getHerbs, getHerbBySlug } from '../../services/herb.services';
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor';
import { BreakpointMobile } from '../Layout/GlobalStyle';


const useStyles = makeStyles({
  buttonTypeOne: {


    borderColor: "#ADB1B8 #A2A6AC #8D9096",
    textTransform: 'initial',
    fontFamily: "var(--Font1NotCondensed)",
  },
  paragraphTypeOne: {
    fontSize: 13,
    color: '#4D4D4D',
    fontFamily: "var(--Font1NotCondensed)",
    marginTop: 0
  }


});

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;

  .name-and-rating{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .herb-review-item-name{
    padding-top: 10px;
    text-align: center;
    line-height: normal;
    padding-bottom: 10px;
    color:   var(--TextTitleColor2);


  }

  .herb-review-rating-wrapper{
    display: flex;
    justify-content: center;
  }

  .button-wrapper{
    padding-top: 20px;
    height: 38px;
  }


  @media only screen and (max-width: ${BreakpointMobile - 100 + 'px'}){
  .button-wrapper{
    height: initial;
  }
  }

`

const HerbPicture = styled.div`
    width: 150px;
    height: 150px;
    background-image: url(${props => props.theme.defaultHerbListIcon});      
    background-repeat: none;
    background-size: cover;
    ${props => props.src ? `background-image: url(${props.src})` : `background-image: url(${props => props.theme.defaultHerbListIcon})`};
`

function SuggestedHerbReviewItem({ herbID }) {
  let [herb, setHerb] = useState({
    _id: "",
    name: "",
    slug: ""
  })
  const [hasCheckedForPicture, setHasCheckedForPicture] = useState(false)
  const [loading, setLoading] = useState(false)

  let [picture, setPicture] = useState("")
  let [ratingValue, setRatingValue] = useState(0)
  const classes = useStyles()

  const handleOnChangeRating = (value) => {
    setRatingValue(value)
  }

  const handleNavigation = () => {
    navigate(`${CREATE_HERB_REVIEW}?id=${herbID}&rating=${ratingValue}`)
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      loadHerb()
    }
    return () => isSubscribed = false;

  }, [herbID])


  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && herb.slug) {
      loadHerbPictures()
    }

    return () => isSubscribed = false;
  }, [herb.slug !== ""])

  const loadHerbPictures = () => {
    getHerbBySlug(herb.slug, `?picturesOnly=true`)
      .then(res => {
        if (res.status === 200) {

          if (res.data && res.data.pictures && res.data.pictures[0]) {
            setPicture(res.data.pictures[0].src)

          }
        }
      })
      .catch(err => {

        throw err
      })
  }

  const loadHerb = () => {
    getHerbs(`?id=${herbID}`)
      .then(res => {
        if (res.status === 200 && res.data) {
          setHerb(res.data)
        }
      })
      .catch(err => {
        throw err
      })
  }

  const onVisibilityChange = (isVisible) => {
    if (isVisible === true && !picture && !hasCheckedForPicture && !loading && herb._id) {
        loadHerbPictures(true)
    }
}



  return (
    <Container>
       <VisibilitySensor onChange={(e) => onVisibilityChange(e)}>
       <Link to={`${CREATE_HERB_REVIEW}?id=${herbID}`}>
        <HerbPicture src={picture} />
      </Link>
      </VisibilitySensor>

      <div className="name-and-rating">
        <Link to={`${CREATE_HERB_REVIEW}?id=${herbID}`}>

        <div className="herb-review-item-name">
          {herb.name}
        </div>
        </Link>

        <div className="herb-review-rating-wrapper">
          <RatingInput onChange={handleOnChangeRating} value={ratingValue} />
        </div>

      </div>

      <div className="button-wrapper">

        {!ratingValue ? <div> </div> : <Button size="small" disableElevation color="secondary" variant={"contained"} onClick={() => handleNavigation()} className={classes.buttonTypeOne}>Write a review</Button>}
      </div>


    </Container>
  )
}


SuggestedHerbReviewItem.defaultProps = {
  herbID: ""
}

SuggestedHerbReviewItem.propTypes = {
  herbID: PropTypes.string.isRequired
}

export default SuggestedHerbReviewItem