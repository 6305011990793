import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getSuggestedHerbsToReview } from '../../services/herb-review.services';
import SuggestedHerbReviewItem from './SuggestedHerbToReviewItem';
import { PageHeaderTypeThree, BreakpointTablet, BreakpointMobile } from '../Layout/GlobalStyle';
import { ManageFavoritesStyled } from '../CreateHerbReview';


const Container = styled.div`

`

const SuggestionGrid = styled.div`
padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 70px;


  @media only screen and (max-width: ${BreakpointTablet - 100 + 'px'}){
    grid-template-columns: 1fr 1fr;

  }
  @media only screen and (max-width: ${BreakpointMobile - 100 + 'px'}){
    grid-template-columns: 1fr;
    grid-row-gap: 30px;

  }
`

function SuggestedHerbsToReview() {
  let [suggestedHerbsToReview, setSuggestedHerbsToReview] = useState([])
  let [loading, setLoading] = useState(false)
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      loadSuggestedHerbsToReview(isSubscribed)
    }

    return () => isSubscribed = false;
  }, [])


  const loadSuggestedHerbsToReview = (isSubscribed) => {
    setLoading(true)
    getSuggestedHerbsToReview()
      .then(res => {
        if (res.status === 200 && isSubscribed) {
          setSuggestedHerbsToReview(res.data.herbs)
          setLoading(false)

          // TO DO
          // MAYBE PUT COUNT AND LIMIT IN LATER
        }
      })
      .catch(err => {
        setLoading(false)

        throw err
      })
  }

  let renderSuggestedHerbsToReview = suggestedHerbsToReview ? suggestedHerbsToReview.map((item, index) => {
    return (<SuggestedHerbReviewItem key={index} herbID={item._id} />)
  }) : ""

  
  return (
    <Container>
      <PageHeaderTypeThree>{loading? "Looking for other herbs to review" :  "Here are some other herbs you could leave a review for"}</PageHeaderTypeThree>
      <SuggestionGrid>

        {renderSuggestedHerbsToReview}
      </SuggestionGrid>

    </Container>
  )
}


export default SuggestedHerbsToReview